import "./scss/index.scss";
import React, { useMemo, useState, useContext } from "react";
import { useIntl } from "react-intl";
import { Button } from 'antd';
import { Svg, LoaderComponent, useConvertedPrice } from "../../common";
import { ParticipateComponent } from "../../event/ParticipateComponent";
import { ParticipantsListContainer } from "../../event/ParticipantsList.container";
import { InvitesFormCourseContainer } from '../../event/InvitesFormCourse.container/InvitesFormCourse.container';
import { EmailVerificationContainer } from '../../user';
import { IncludesContainer } from '../../enroll/Includes.container/Includes.container';
import { StripeSubscriptionContainer } from '../../stripe';
import {PaypalContainer} from '../../paypal';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const EnrollComponent = (props) => {
    const {
        event,
        paymentError,
        workspaceSubscription,
        handleParticipate,
        handleRemoveParticipant,
        user,
        isStripeConnected,
        isEditor,
        loading,
        isNotVerified,
        onJoin,
        onLogin,
        stripeAccountId,
        ...other
    } = props;

    const {workspace} = useContext(FirebaseContext);
    const { formatMessage } = useIntl();
    const { getPrice } = useConvertedPrice();
    const [showModalVerification, setShowModalVerification] = useState();
    const {activePaymentSystem = 'STRIPE'} = workspace || {};
    const { isConnectedAccountButton, isFree, isStripeSubscription, isPaypal, isStripe, isSubscribed} = useMemo(() => {
        return {
            isConnectedAccountButton: !!(isStripeConnected && event?.price),
            isFree: !event?.price,
            isStripeSubscription: !workspaceSubscription && !!event?.stripeProductId && !!event?.stripeProduct?.[0]?.prices?.length,
            isPaypal: activePaymentSystem === 'PAYPAL',
            isStripe: activePaymentSystem === 'STRIPE',
            isSubscribed: event?.isSubscribe
        }
    }, [event, isStripeConnected, workspaceSubscription, activePaymentSystem]);

    const handleJoin = () => {
        onJoin();
        // if (isNotVerified) {
        //     setShowModalVerification(true);
        // } else {
        //     onJoin();
        // }
    }

    const handleCheckParticipate = (data) => {
        handleParticipate(data);
        // if (isNotVerified) {
        //     setShowModalVerification(true);
        // } else {
        //     handleParticipate(data);
        // }
    };
    return (
        <div className="course-schedule ">
            <p className="wrapper_inner-section" dangerouslySetInnerHTML={{ __html: event.result, }} />
            {loading && (<LoaderComponent type="overlay" />)}

            <div>
                {!isSubscribed && !isFree && isPaypal && (
                    <PaypalContainer {...other}
                                     price={event?.price}
                                     entityId={event?.id}/>
                )}

                {!isSubscribed && !isFree && isStripe && isStripeSubscription && (
                    <StripeSubscriptionContainer event={event}
                                                 stripeAccountId={stripeAccountId}
                                                 onLogin={onLogin} />
                )}

                {isSubscribed && (
                    <div className="booking__promo ">
                        <div className="wrapper_inner-section">
                            <Svg
                                name=":icon/check"
                                className="icon_message icon_24"
                            />
                            <span>
                                {formatMessage({ id: "event_you_in", })}
                            </span>
                        </div>
                        <div>
                            <Button
                                className='inline-el margin_right'
                                onClick={() => handleRemoveParticipant(user?.id)}
                            >
                                {formatMessage({ id: 'unsubcribe_button' })}
                            </Button>
                            {/* <AddCalendarContainer event={event} /> */}
                        </div>
                    </div>
                )}

                {!isSubscribed && isStripe && !isStripeSubscription && (
                    <div className="event-component__participate">
                        <small>
                            {event.priceSubscription
                                ? formatMessage({ id: 'subscription_title' })
                                : (event?.price || event.priceSubscription) ?
                                    formatMessage({ id: 'purchase_title' })
                                    : null
                            }
                        </small>
                        <div className="event_price">
                            {(event?.price || event.priceSubscription) && !workspaceSubscription ? (
                                <div className="inline-el">
                                    {
                                        getPrice(event.priceSubscription
                                            ? event.priceSubscription?.forStudent
                                            : event.price?.forStudent
                                        )
                                    }
                                </div>
                            ) : null

                            }
                        </div>
                        {isConnectedAccountButton && !workspaceSubscription && (
                            <Button type="primary"
                                    block="true"
                                    disabled={loading}
                                    onClick={handleJoin}>
                                {formatMessage({ id: 'event_enroll_course' })}
                            </Button>
                        )}

                        {paymentError && (
                            <div className="error">
                                {formatMessage({ id: 'payment_error_message' })}
                            </div>
                        )}

                        {(!isConnectedAccountButton || workspaceSubscription) && (
                            <ParticipateComponent
                                {...other}
                                workspaceSubscription={workspaceSubscription}
                                classProps=""
                                isSubscribeForPeriod={!!event?.listTimeData?.filter((e) => e.type === "WEEKLY")?.length}
                                onParticipate={handleCheckParticipate}
                                event={event}
                                block="true"
                                isFree={isFree}
                            />
                        )}
                    </div>
                )}

                {isNotVerified && (
                    <EmailVerificationContainer showModal={showModalVerification}
                                                setShowModal={setShowModalVerification} />
                )}

                <IncludesContainer
                    event={event}
                    user={user}mai
                    {...other}
                />

                {isEditor ? (
                    // <ParticipantsListContainer
                    //     userSubscriptions={event.userSubscriptions}
                    //     handleRemoveParticipant={handleRemoveParticipant}
                    //     isEditor={isEditor}
                    //     user={user}
                    //     event={event}
                    //     {...other}
                    // />
                    <InvitesFormCourseContainer
                        {...other}
                        event={event}
                    />
                ) : event.countUserSubscriptions > 20 ?
                    <div className="booking__promo ">
                        <span className=' margin_right'> {formatMessage({ id: 'participants_list_title' })}: {event.countUserSubscriptions}</span>
                    </div>
                    : null
                }
            </div>
        </div>
    );
};
